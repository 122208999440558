































































import Vue from 'vue';
import MainNav from '@/components/MainNav.vue';
import Splash from '@/components/Splash.vue';
import moment from 'moment';

let realStreamCheckerTimeout = 0;
const CEREMONY_DATE = moment("2021-08-14T21:00:00.000Z").toDate();

export default Vue.extend({
  components: {
    MainNav,
    Splash,
  },

  data() {
    return {
      loaded: false,
      splashOpen: false,
      showRealStream: this.$route.query.no_the_real_stream === "please",
    };
  },

  computed: {
    loading(): boolean {
      return !this.loaded;
    },

    humanDuration(): string {
      const nowMoment = moment();
      const ceremonyMoment = moment(CEREMONY_DATE);
      return nowMoment.to(ceremonyMoment);
    },
  },

  created(): void {
    const checkIfItsTimeForTheRealStream = (): void => {
      window.clearTimeout(realStreamCheckerTimeout);

      if (this.itsTimeForTheRealStreamGuys()) {
        this.showRealStream = true;
      } else {
        realStreamCheckerTimeout = window.setTimeout(() => {
          checkIfItsTimeForTheRealStream();
        }, 5000);
      }
    };

    checkIfItsTimeForTheRealStream();
  },

  beforeDestroy(): void {
    window.clearTimeout(realStreamCheckerTimeout);
  },

  methods: {
    onStreamFrameLoaded(): void {
      this.loaded = true;
      if (!this.showRealStream) this.splashOpen = true;
    },

    onCloseSplash(): void {
      this.splashOpen = false;
    },

    itsTimeForTheRealStreamGuys(): boolean {
      if (this.showRealStream) return true;

      const nowMoment = moment();
      const dayBeforeCeremonyMoment = moment(CEREMONY_DATE).subtract(1, "day");
      return nowMoment.isAfter(dayBeforeCeremonyMoment);
    },

    startCheckingToSeeIfItsTimeForTheRealStreamWhewThatsALongMethodName(): void {
      const checkIfItsTimeForTheRealStream = (): void => {
        window.clearTimeout(realStreamCheckerTimeout);

        if (this.itsTimeForTheRealStreamGuys()) {
          this.showRealStream = true;
        } else {
          realStreamCheckerTimeout = window.setTimeout(() => {
            checkIfItsTimeForTheRealStream();
          }, 5000);
        }
      };

      checkIfItsTimeForTheRealStream();
    },
  },
});
